<template>
  <skeleton>
    <div class="h-12 w-28 rounded-md"></div>
  </skeleton>
</template>

<script>
import Skeleton from "../components/Skeleton/index.vue";
export default {
  components: { Skeleton },
};
</script>

<style>
</style>