<template>
  <Skeleton>
    <div class="relative pt-[56.25%]">
      <div class="w-full h-full absolute left-0 top-0 rounded-md"></div>
    </div>
  </Skeleton>
</template>

<script>
import Skeleton from "../components/Skeleton/index.vue";
export default {
  components: { Skeleton },
};
</script>

<style>
</style>