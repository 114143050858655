<template>
    <video-carousel-skeleton v-if="isLoading"/>
    <SectionItem v-else v-for="(item, index) in $store.state[getter]" :key="index" :title="item.title"
                 :items="item.medias" :more-link="`/series/all/all/all/all?t=home&id=${item.id}&ptitle=${item.title}`"/>
</template>

<script>
import VideoCarouselSkeleton from "../skeletons/VideoCarouselSkeleton.vue";
import SectionItem from "./SectionItem.vue";

export default {
  props: ["action", "getter"],
  components: {SectionItem, VideoCarouselSkeleton},
  data() {
    return {
      isLoading: true,
    }
  },
  created() {
    this.$store.dispatch(this.action, {vm: this})
  }
};
</script>

<style>
</style>